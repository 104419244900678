import React from 'react';
import { Products as ProductTypes } from '~/models/products.d';
import { Link } from '~/shared/link';
import { AddToBasketContainer } from '~/shared/add-to-basket/add-to-basket.container';
import { StockStatusWithStockQuery } from '~/shared/stock-status/stock-status.component';
import { FavoriteButton } from '~/shared/add-favorite/add-favorite.component';
import { IColliPrice } from '~/shared/colli-prices/colli-prices.component';
import { FavoriteType } from '../../W041Favorites';
import Price from '~/shared/price/price.component';
import FtzPricesTooltip from '~/shared/ftz-prices-tooltip/ftz-prices-tooltip.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import ProductImageTitleBrand from '~/shared/product-image-title-brand/product-image-title-brand.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import styles from './styled.module.scss';

interface IProps {
    product: ProductTypes.IFavoriteProduct;
    favoriteType?: FavoriteType;
    price?: ProductTypes.IVariantPrices;
    stock?: ProductTypes.IStockStatus;
    // For adding or removing favorite + tracking of action
    onClick: (product: ProductTypes.IFavoriteProduct, status?: ProductTypes.FavoriteStatus, price?: number) => void;
    // Current status of favorite product
    status?: ProductTypes.FavoriteStatus;
}

export default function FavoriteProduct({ product, price, stock, onClick, status }: IProps) {
    const translate = useTranslations();

    const { itemId, image, brand, title, url, colli, colliLocked, productType } = product;
    const isDummyProduct = productType === ProductTypes.ProductType.DummyProduct;

    return (
        <li className={styles.product}>
            <div className={styles.titleCell}>
                <ProductImageTitleBrand image={image} name={title} brand={brand} />
            </div>

            <ValueWithCaption captionClassName={styles.hideOnMobile} className={styles.itemNoCell} caption={translate('product.itemNo', 'Vare nr.')}>
                <Link href={isDummyProduct ? undefined : url}>
                    <Text textStyle="monoMedium" className={styles.itemId}>
                        {itemId}
                    </Text>
                </Link>
            </ValueWithCaption>

            <ValueWithCaption captionClassName={styles.hideOnMobile} className={styles.ftzNoCell} caption={translate('product.ftzNo', 'FTZ Nr.')}>
                <FtzPricesTooltip unit={product?.unit} prices={price as IColliPrice} colli={colli} colliLocked={colliLocked} />
            </ValueWithCaption>

            <ValueWithCaption
                className={styles.priceCell}
                caption={
                    product?.unit
                        ? translate('product.pricePerUnit', 'Pris pr. [unit]').replace('[unit]', product?.unit)
                        : translate('product.pricePerPiece', 'Pris stk.')
                }
            >
                <Price>{price?.actualPrice?.displayPrice || '-'}</Price>
            </ValueWithCaption>

            <ValueWithCaption
                captionClassName={styles.hideOnMobile}
                className={styles.stockStatusCell}
                caption={translate('product.stockStatus', 'Lagerstatus')}
            >
                {!isDummyProduct ? <StockStatusWithStockQuery className={styles.stockStatus} itemNo={itemId} /> : null}
            </ValueWithCaption>

            <div className={styles.addToBasketCell}>
                <FavoriteButton
                    handleClick={() => onClick(product, status, price?.actualPrice?.customerPrice)}
                    disabled={status === ProductTypes.FavoriteStatus.ChainFavorite}
                    status={status}
                />
                <AddToBasketContainer
                    disabled={isDummyProduct}
                    label={translate('product.buy', 'Køb')}
                    syncWithBasket
                    url={product.url as string}
                    productDetails={{
                        itemNo: itemId,
                        itemName: title,
                        customerPrice: price?.actualPrice?.customerPrice || null,
                        colli: colli,
                        colliLocked: colliLocked,
                    }}
                />
            </div>
        </li>
    );
}
