import { useEffect, useRef } from 'react';
import { Bff } from '~/models/bff';
import { addErrorToast, addSuccessToast, useLayoutDispatch } from '~/context/layout.context';
import { IPaperJS } from '~/types/ipaper';
import { useCurrentBasket, useUpdateBasket, useUpdateBasketHandlers } from '~/libs/queries/basket';
import { getFlattendedGroupItems } from '~/libs/ga4/utils';

import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import styles from './styled.module.scss';

// Before: ipaper.component.tsx
const M180iPaper = ({ link }: Bff.IM180IPaperModuleViewModel): JSX.Element => {
    const url = Array.isArray(link) ? link[0]?.url : link?.url || '';

    const dispatch = useLayoutDispatch();
    const translate = useTranslations();

    const scriptRef = useRef<HTMLScriptElement>();
    const apiInstanceRef = useRef<IPaperJS>();
    const frameRef = useRef<HTMLIFrameElement>(null);

    const { data: currentBasket } = useCurrentBasket();

    const { handleSuccess: handleUpdateBasketSuccess } = useUpdateBasketHandlers();
    const { mutate: updateBasket, isLoading: isUpdatingBasket } = useUpdateBasket({
        onSuccess: (data, variables, ...args) => {
            handleUpdateBasketSuccess(data, variables, ...args);

            const flattendedUpdatedItems = getFlattendedGroupItems(data.groups);
            const updatedItem = flattendedUpdatedItems?.find((item) => item.itemId === variables.details.itemId);

            dispatch(addSuccessToast(translate('ipaper.success', '[item] blev tilføjet til kurven.').replace('[item]', updatedItem?.name ?? '')));
        },
        onError: () => {
            dispatch(addErrorToast(translate('common.somethingWentWrong', 'Der gik noget galt.')));
        },
    });

    useEffect(() => {
        scriptRef.current = document.createElement('script');
        scriptRef.current.src = 'https://cdn.ipaper.io/flipbooks/api/v3/latest.js';
        scriptRef.current.async = true;
        const handleLoad = () => {
            if (!frameRef.current) {
                console.error('IPaper IFrame was not mounted for: ' + url);
                return;
            }

            // Create a new V3 API instance with the iframe
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            apiInstanceRef.current = window.iPaperJsApi(frameRef.current, 3);
            apiInstanceRef.current.basket.onProductAdded(async (product) => {
                if (!currentBasket) {
                    console.log('Could not add item from iPaper. Current basket was not initialized.');
                    return;
                }

                if (!product) {
                    console.log('Could not add item from iPaper. Event fired did not contain any IPaperProduct.');
                    return;
                }

                const { productId, title, quantity, price } = product;

                updateBasket({
                    basketBeforeUpdate: currentBasket,
                    skipValidation: true,
                    details: {
                        basketId: currentBasket?.id,
                        itemId: productId,
                        count: quantity,
                        ftzPrice: price,
                        url: '',
                        itemName: title,
                    },
                });
            });
        };

        scriptRef.current.addEventListener('load', handleLoad);
        document.body.appendChild(scriptRef.current);

        return () => {
            apiInstanceRef.current?.destroy();
            scriptRef.current?.removeEventListener('load', handleLoad);
            document.body.removeChild(scriptRef.current as Node);
        };
    }, [currentBasket, scriptRef]);

    return (
        <>
            <div className={styles.iPaperWrapper}>
                {isUpdatingBasket ? (
                    <div className={styles.loaderWrapper}>
                        <Loader />
                    </div>
                ) : null}
                <iframe src={url as string} className={styles.frame} title="iPaper" data-ipaper ref={frameRef} allow="fullscreen" />
            </div>
        </>
    );
};

export default M180iPaper;
