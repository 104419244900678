import React, { FC, useCallback, useState } from 'react';
import useDepositProducts from '~/libs/queries/products/hooks/use-deposit-products-service';
import Button from '~/shared/buttons/button/button.component';
import ErrorBox from '~/shared/error-box/error-box';
import Select from '~/shared/form-elements/select/select.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import styles from './create-deposit-credit.module.scss';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import Text from '~/shared/text/text.component';
import { Link } from '~/shared/link';
import Price from '~/shared/price/price.component';
import { DEPOSIT_ITEMS_COUNT } from '~/constants/deposit-items-count';
import { InvoiceDraftEntry } from '~/widgets/overview/invoices-widget/invoice-draft/invoice-draft';
import { generateUniqueId } from '~/widgets/overview/invoices-widget/invoice-draft/utils';
import { useCreditDraftStorage } from '~/widgets/overview/invoices-widget/invoice-draft/hooks';
import { updateOrInsertEntries } from '~/page-elements/credit-modal/utils';

interface IProps {
    onClose: () => void;
    depositMatchId?: string;
}

const CreateDepositCredit: FC<IProps> = ({ onClose, depositMatchId }) => {
    const translate = useTranslations();

    const [selected, setSelected] = useState<string>(depositMatchId ?? '');
    const [quantity, setQuantity] = useState<string>('');
    const [, setStoredCreditDraft] = useCreditDraftStorage();

    const { data: productsData, isLoading: isLoadingProducts, isError } = useDepositProducts();

    const selectedProduct = selected ? productsData?.find((item) => item.id.toString() === selected) : null;

    const handleCreateDepositCredit = useCallback(() => {
        if (!selectedProduct?.ftzCode || !quantity.length) {
            return;
        }

        const entry: InvoiceDraftEntry = {
            itemNo: selectedProduct.ftzCode,
            depositOnly: true,
            hasDeposit: true,
            quantity: Number(quantity),
            uniqueId: generateUniqueId(selectedProduct.ftzCode, 1, 'deposit'),
            placeholder: {
                name: selectedProduct.variantName ?? '',
            },
        };

        setStoredCreditDraft((prevStoredDraft) => ({
            ...prevStoredDraft,
            entries: updateOrInsertEntries(prevStoredDraft.entries, [entry]),
        }));

        onClose();
    }, [onClose, quantity, selectedProduct?.ftzCode, selectedProduct?.variantName, setStoredCreditDraft]);

    if (isLoadingProducts) return <Loader />;

    if (isError) {
        return <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>;
    }

    return (
        <div>
            <Select
                disabled={Boolean(depositMatchId)}
                className={styles.select}
                label={translate('createCredit.selectProductFromTheList', 'Vælg produkt i listen du ønsker at lave depositum på')}
                value={selected}
                onChange={(e) => {
                    setSelected(e.target.value || '');
                    setQuantity('');
                }}
            >
                <option value="">-</option>
                {productsData?.map((item) => (
                    <option value={item?.id} key={item.ftzCode}>
                        {item?.title}
                    </option>
                ))}
            </Select>
            <div className={styles.item}>
                {selectedProduct && (
                    <>
                        <Select
                            className={styles.select}
                            label={translate('overview.selectQuantity', 'vælg Antal')}
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value || '')}
                        >
                            <option value="">-</option>
                            {Array.from(Array(DEPOSIT_ITEMS_COUNT).keys())
                                .map((i) => i + 1)
                                .map((n) => (
                                    <option key={`${selected}-${n}`}>{n}</option>
                                ))}
                        </Select>
                        <div className={styles.details}>
                            <ValueWithCaption caption={translate('product.itemNo', 'Vare nr')}>
                                <Link href={selectedProduct.url}>
                                    <Text textStyle="monoMedium">{selectedProduct.ftzCode}</Text>
                                </Link>
                            </ValueWithCaption>
                            <Price upperCase>
                                {selectedProduct.displayDeposit} \ {translate('product.piece', 'stk')}
                            </Price>
                        </div>
                    </>
                )}
            </div>

            <Button onClick={handleCreateDepositCredit} disabled={!selected || !quantity}>
                {translate('createCredit.createDeposit', 'Opret depositum')}
            </Button>
        </div>
    );
};

export default CreateDepositCredit;
