import clsx from 'clsx';
import React, { FC, useMemo, useState } from 'react';
import { Bff } from '~/models/bff';
import { IWithClassName } from '~/models/dev';
import { Products as ProductTypes } from '~/models/products';
import { AddToBasketContainer } from '~/shared/add-to-basket/add-to-basket.container';
import BrandLogo from '~/shared/brand-logo/brand-logo.component';
import { IAllColliPrices, IColliPriceItem } from '~/shared/colli-prices/colli-prices.component';
import ErrorBox from '~/shared/error-box/error-box';
import FtzPricesTooltip from '~/shared/ftz-prices-tooltip/ftz-prices-tooltip.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { CdnImage } from '~/shared/image/cdn-image';
import { Link } from '~/shared/link';
import Price from '~/shared/price/price.component';
import ProductAccessoryPlacement from '~/shared/ProductCard/ProductAccessoryPlacement';
import Skeleton from '~/shared/skeleton';
import { StockStatus } from '~/shared/stock-status/stock-status.component';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import Tooltip from '~/shared/tooltip/tooltip.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import styles from './product-card-small.module.scss';
import getProductVariantCriteriaText from '~/utils/getProductVariantCriteriaText';
import { isVariantLight } from '~/libs/ga4/utils';

interface IProductCardSmall extends IWithClassName {
    product?: ProductTypes.IVariantLight;
    isLoading?: boolean;
    isError?: boolean;
    relatedVariantsNecessityInfo?: ProductTypes.IRelatedVariant[];
    listName?: string;
    price?: ProductTypes.IVariantPrices;
    stockStatus?: ProductTypes.IStockStatus;
    extraInfo?: ProductTypes.IProductArticleWithFtzCode;
}

const ProductCardSmallContainer: FC<IProductCardSmall> = ({
    product,
    className,
    isError,
    relatedVariantsNecessityInfo = [],
    listName,
    price,
    stockStatus,
    extraInfo,
}) => {
    const { image, variantName, url, brand, ftzCode, colli, colliLocked, unit, subGroup } = product || {};
    const translate = useTranslations();
    const badge = useMemo(() => relatedVariantsNecessityInfo.find((x) => x.variantId === product?.id), [relatedVariantsNecessityInfo, product?.id]);

    if (isError) {
        return (
            <div className={clsx(className, styles.product)}>
                <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>
            </div>
        );
    }

    return (
        <ProductCardSmall
            url={url as string}
            item={product}
            image={image}
            price={price}
            stockStatus={stockStatus}
            colli={colli}
            colliLocked={colliLocked}
            className={className}
            subGroup={subGroup}
            listName={listName}
            brand={brand}
            unit={unit}
            title={variantName}
            ftzCode={ftzCode}
            necessityLevel={badge}
            extraInfo={extraInfo}
        />
    );
};

export type ProductCardSmallWrapperProps = {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
};

export function ProductCardSmallWrapper({ children, className }: ProductCardSmallWrapperProps) {
    return <div className={clsx(className, styles.product)}>{children}</div>;
}

export type ProductCardSmallButtonWrapperProps = {
    children: React.ReactNode | React.ReactNode[];
};

export function ProductCardSmallButtonWrapper({ children }: ProductCardSmallButtonWrapperProps) {
    return <div className={styles.addToBasket}>{children}</div>;
}

export type ProductCardSmallFooterProps = {
    children: React.ReactNode | React.ReactNode[];
};

export function ProductCardSmallFooter({ children }: ProductCardSmallFooterProps) {
    return <div className={styles.footer}>{children}</div>;
}

export type ProductCardSmallBodyProps = {
    url: string;
    necessityLevel?: ProductTypes.IRelatedVariant;
    title?: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    subGroup?: string;
    image?: Bff.IImage;
    brand?: ProductTypes.IBrand;
    colli?: number;
    colliLocked?: boolean;
    unit?: string;
    ftzCode?: string;
    stockStatus?: ProductTypes.IStockStatus;
    price?: ProductTypes.IVariantPrices;
    placementFields: ProductTypes.IPlacementField[] | undefined;
    extraInfo?: ProductTypes.IProductArticleWithFtzCode;
};

export function ProductCardSmallBody({
    necessityLevel,
    onClick,
    image,
    url,
    brand,
    title,
    subGroup,
    unit,
    colli,
    colliLocked,
    ftzCode,
    stockStatus,
    price,
    placementFields,
    extraInfo,
}: ProductCardSmallBodyProps) {
    const translate = useTranslations();
    const [imageIsLoaded, setImageIsLoaded] = useState(false);

    const productAccessoryPlacement = useMemo(
        () => placementFields?.map((item) => item.text).filter((item): item is string => !!item),
        [placementFields],
    );

    return (
        <>
            {necessityLevel?.necessityLevelAlias?.length ? (
                <Text textStyle="body" fontWeight="semiBold" textAlign="left" tagName="p" className={styles.necessity}>
                    {necessityLevel.necessityLevelLocalized}
                </Text>
            ) : null}
            <Link href={url} className={styles.link} onClick={onClick}>
                {image?.url ? (
                    <div className={styles.image}>
                        <CdnImage
                            layout="fixed"
                            objectFit="contain"
                            src={image?.url}
                            alt={image?.altText}
                            height={198}
                            width={245}
                            draggable={false}
                            className={clsx(styles.imageElement, {
                                [styles.imageLoaded]: imageIsLoaded,
                            })}
                            onLoad={(event) => {
                                const target = event.target as HTMLImageElement;

                                // next/image use an 1x1 px git as placeholder. We only want the onLoad event on the actual image
                                if (target.src.indexOf('data:image/gif;base64') < 0) {
                                    setImageIsLoaded(true);
                                }
                            }}
                        />
                    </div>
                ) : (
                    <Svg name="placeholder-image" className={styles.imagePlaceholder} />
                )}
                {extraInfo?.criteria ? (
                    <Tooltip
                        className={styles.tooltip}
                        content={
                            <Text color="bodyColor" className={styles.tooltipContent}>
                                {getProductVariantCriteriaText(extraInfo.criteria)}
                            </Text>
                        }
                    >
                        <Text textStyle="bodySmall">{translate('product.extraInfoCarTooltip', 'Bilspecifik!')}</Text>
                    </Tooltip>
                ) : null}
            </Link>
            <div className={styles.body}>
                <div className={styles.brandStock}>
                    <div className={styles.brand}>
                        <BrandLogo brand={brand} />
                    </div>

                    <div className={styles.stockStatus}>
                        <StockStatus stock={stockStatus} state="ready" />
                    </div>
                </div>

                <Link href={url} className={styles.link} onClick={onClick}>
                    <Text textStyle="body" fontWeight="semiBold" className={styles.title}>
                        {title}
                    </Text>
                    <Text textStyle="bodySmall">{ftzCode}</Text>
                </Link>
                <FtzPricesTooltip
                    unit={unit}
                    prices={{
                        actualPrice: price?.actualPrice as IColliPriceItem,
                        allPrices: price?.allPrices as IAllColliPrices[],
                    }}
                    colli={colli}
                    colliLocked={colliLocked}
                />
                {productAccessoryPlacement?.length ? <ProductAccessoryPlacement placements={productAccessoryPlacement} /> : null}
            </div>
        </>
    );
}

export type ProductCardSmallPriceProps = {
    unit?: string;
    price?: ProductTypes.IVariantPrices;
};

export function ProductCardSmallPrice({ unit, price }: ProductCardSmallPriceProps) {
    const translate = useTranslations();

    return (
        <ValueWithCaption
            caption={
                unit ? translate('product.pricePerUnit', 'Pris pr. [unit]').replace('[unit]', unit) : translate('product.pricePerPiece', 'Pris stk.')
            }
        >
            <Price>{price?.actualPrice?.displayPrice || translate('product.callForPrice', 'Ring for pris')}</Price>
        </ValueWithCaption>
    );
}

export type ProductCardSmall = {
    url: string;
    className?: string;
    image?: Bff.IImage;
    brand?: ProductTypes.IBrand;
    subGroup?: string;
    necessityLevel?: ProductTypes.IRelatedVariant;
    listName?: string;
    price?: ProductTypes.IVariantPrices;
    stockStatus?: ProductTypes.IStockStatus;
    title?: string;
    ftzCode?: string;
    colli?: number;
    colliLocked?: boolean;
    unit?: string;
    item?: ProductTypes.IVariantLight | ProductTypes.IProductTile;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    onAddToBasket?: () => void;
    promotionId?: string;
    componentId?: string;
    componentName?: string;
    campaignSpot?: string;
    extraInfo?: ProductTypes.IProductArticleWithFtzCode;
};

export function ProductCardSmall({
    url,
    className,
    image,
    necessityLevel,
    brand,
    subGroup,
    listName,
    price,
    stockStatus,
    title,
    ftzCode,
    colli,
    colliLocked,
    unit,
    item,
    onClick,
    onAddToBasket,
    promotionId,
    componentId,
    componentName,
    campaignSpot,
    extraInfo,
}: ProductCardSmall) {
    if (!item) {
        return (
            <div className={clsx(className, styles.product)}>
                <Skeleton style={{ height: '100%', width: '100%' }} />
            </div>
        );
    }

    return (
        <ProductCardSmallWrapper className={className}>
            <ProductCardSmallBody
                url={url}
                title={title}
                necessityLevel={necessityLevel}
                ftzCode={ftzCode}
                stockStatus={stockStatus}
                colli={colli}
                colliLocked={colliLocked}
                unit={unit}
                price={price}
                image={image}
                onClick={onClick}
                subGroup={subGroup}
                brand={brand}
                placementFields={isVariantLight(item) ? item.variantPlacementFields : undefined}
                extraInfo={extraInfo}
            />

            <ProductCardSmallFooter>
                <ProductCardSmallPrice unit={unit} price={price} />

                <ProductCardSmallButtonWrapper>
                    <AddToBasketContainer
                        className={styles.addToBasket}
                        isLarge
                        syncWithBasket
                        listName={listName}
                        url={url}
                        onAddToBasket={onAddToBasket}
                        productDetails={{
                            itemName: title,
                            itemNo: ftzCode as string,
                            customerPrice: price?.actualPrice?.customerPrice as number,
                            colli: colli,
                            colliLocked: colliLocked,
                        }}
                        tracking={{
                            campaignType: componentName,
                            campaignSpot: campaignSpot,
                            campaignId: componentId,
                            raptorSource: promotionId,
                        }}
                    />
                </ProductCardSmallButtonWrapper>
            </ProductCardSmallFooter>
        </ProductCardSmallWrapper>
    );
}

export default ProductCardSmallContainer;
