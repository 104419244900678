import { Products, Products as ProductTypes } from '~/models/products.d';
import React, { useEffect, useRef, useState } from 'react';
import { ProductListRowPages } from './product-list-rows/product-list-rows.component';
import Loader from '~/shared/loader/loader.component';
import LoadMore from '~/shared/load-more/load-more.component';
import LightBox from '../light-box/light-box.component';
import { useRouter } from 'next/router';
import {
    ProductListType,
    useExhaustProductListFacets,
    usePictogramProductListFacets,
    useProductList,
    useSearchProductListFacets,
    useUniversalProductListFacets,
} from '~/libs/queries/products';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { ProductListHead, SearchProductListHead, useSortedCategories } from '~/shared/product-list-header/product-list-header.component';
import { ProductListTilePages } from '~/shared/product-list/product-list-tiles/product-list-tiles.component';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { GA4SearchFullSearch } from '~/libs/ga4';
import { useProductsVehicleInfoByIds } from '~/libs/queries/vehicles/hooks/use-product-vehicle-info-by-ids';
import { Bff } from '~/models/bff.d';
import { Vehicles } from '~/models/vehicles.d';
import styles from '~/page-elements/product-list-page/product-list-page.module.scss';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import debounce from 'lodash/debounce';
import { useCarInfo } from '~/widgets/car-info-banner/use-car-info-banner.hook';

export function useProductListMode(): ProductListType {
    const {
        query: { viewType },
    } = useRouter();

    if (Number(viewType) === ProductTypes.CategoryDefaultViewType.TilesListView) {
        return 'tile';
    }

    return 'list';
}

export type CategoryDefaultViewType = 0 | 1;

export type ProductListWrapperProps = {
    children: React.ReactElement | React.ReactElement[];
    categoryDefaultViewType?: CategoryDefaultViewType;
};

export function ProductListWrapper({ children, categoryDefaultViewType = 0 }: ProductListWrapperProps) {
    const { query, replace } = useRouter();

    const [showScrollTopButton, setShowScrollTopButton] = useState(false);

    useEffect(() => {
        const handleScroll = debounce(() => {
            setShowScrollTopButton((prev) => {
                const scrollPos = window?.scrollY > 300;

                if (prev === scrollPos) {
                    return prev;
                }

                return scrollPos;
            });
        }, 250);

        window?.addEventListener('scroll', handleScroll, {
            passive: true,
        });

        return () => {
            window?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        let viewType: ProductTypes.CategoryDefaultViewType;

        const newQuery = { ...query };
        delete newQuery.slug;

        if (query?.viewType !== undefined) {
            viewType = Number(query?.viewType);
        } else {
            viewType = categoryDefaultViewType;
        }

        const url = createUrl({
            endpoint: window.location.pathname,
            localeOption: LocaleOptions.omit,
            query: {
                ...newQuery,
                viewType,
            },
        });

        if (viewType && viewType !== Number(query?.viewType)) {
            replace(url, undefined, {
                shallow: true,
            });
        }
    }, [categoryDefaultViewType, query, replace]);

    const handleScrollButtonClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
            {children}
            <div className={showScrollTopButton ? styles.scrollToTopBtnActive : styles.scrollToTopBtn}>
                <ButtonCircle iconName="chevron-up" onClick={handleScrollButtonClick} />
            </div>
        </>
    );
}

export type ExhaustProductListProps = {
    title?: string;
    exhaustSystemId: string;
    carId?: string;
    image?: Bff.IImage;
};

export function ExhaustProductList({ title, exhaustSystemId, carId, image }: ExhaustProductListProps) {
    const viewMode = useProductListMode();

    const { data: filters, isLoading: isLoadingFilters } = useExhaustProductListFacets({
        type: viewMode,
        exhaustSystemId,
    });

    const { query, getNextPage } = useExhaustProductList({ type: viewMode, exhaustSystemId });
    const { data: productsVehicleInfo } = useProductsVehicleInfoByIds({
        shouldFetch: true,
    });

    const vehicleInfoText = productsVehicleInfo?.productInfos?.length
        ? productsVehicleInfo?.productInfos
              ?.find((p: Products.IProductInfoText) => p?.productId.toString() === exhaustSystemId.toString())
              ?.info?.join(', ')
        : undefined;

    return (
        <>
            <ProductListHead
                title={title}
                isLoadingFilters={isLoadingFilters}
                filters={filters}
                image={image}
                imageSubText={vehicleInfoText}
                categoryId={exhaustSystemId}
            />
            {viewMode === 'tile' ? (
                <ProductTileView
                    query={query as UseInfiniteQueryResult<ProductTypes.IPagedCategoryResultOfProductTile>}
                    onGetNextPage={getNextPage}
                />
            ) : (
                <ProductListView
                    query={query as UseInfiniteQueryResult<ProductTypes.IPagedCategoryResultOfProduct>}
                    onGetNextPage={getNextPage}
                    carId={carId}
                />
            )}
        </>
    );
}

export function SearchProductList() {
    const {
        query: { query: searchTerm, carModelTypeId },
    } = useRouter();

    const viewMode = useProductListMode();

    const {
        data: filters,
        isLoading: isLoadingFilters,
        isFetching: isFetchingFilters,
    } = useSearchProductListFacets({
        type: viewMode,
    });

    const { query, getNextPage } = useSearchProductList({ type: viewMode });

    const searchTermRef = useRef('');

    useEffect(() => {
        if (isFetchingFilters || typeof searchTerm !== 'string' || searchTermRef.current === searchTerm) {
            return;
        }

        searchTermRef.current = searchTerm;
        GA4SearchFullSearch(searchTerm, filters?.totalResults ?? 0);
    }, [searchTerm, filters?.totalResults, isFetchingFilters]);

    return (
        <>
            <SearchProductListHead isLoadingFilters={isLoadingFilters} filters={filters} />
            {viewMode === 'tile' ? (
                <ProductTileView
                    query={query as UseInfiniteQueryResult<ProductTypes.IPagedCategoryResultOfProductTile>}
                    onGetNextPage={getNextPage}
                />
            ) : (
                <ProductListView
                    carId={typeof carModelTypeId === 'string' ? carModelTypeId : undefined}
                    query={query as UseInfiniteQueryResult<ProductTypes.IPagedCategoryResultOfProduct>}
                    onGetNextPage={getNextPage}
                />
            )}
        </>
    );
}

export type PictogramProductListProps = {
    title?: string;
    carId?: string;
    pictogramId?: string;
    pictogramGroupAlias?: string;
    pictogramGroupType?: string;
    categoryId: string;
};

export function PictogramProductList({ title, pictogramId, pictogramGroupAlias, pictogramGroupType, carId, categoryId }: PictogramProductListProps) {
    const viewMode = useProductListMode();

    const { data: filters, isLoading: isLoadingFilters } = usePictogramProductListFacets({
        type: viewMode,
        pictogramId,
        pictogramGroupType,
        pictogramGroupAlias,
    });

    const { query, getNextPage } = usePictogramProductList({
        type: viewMode,
        pictogramId,
        pictogramGroupAlias,
        pictogramGroupType,
    });
    const { data: { pages: [page] = [] } = {} } = query;

    const sortedSisterCategories = useSortedCategories(page?.sisterCategories);

    return (
        <>
            <ProductListHead
                title={title}
                isLoadingFilters={isLoadingFilters}
                filters={filters}
                sisterCategories={sortedSisterCategories}
                categoryId={categoryId}
            />
            {viewMode === 'tile' ? (
                <ProductTileView
                    query={query as UseInfiniteQueryResult<ProductTypes.IPagedCategoryResultOfProductTile>}
                    onGetNextPage={getNextPage}
                />
            ) : (
                <ProductListView
                    query={query as UseInfiniteQueryResult<ProductTypes.IPagedCategoryResultOfProduct>}
                    onGetNextPage={getNextPage}
                    carId={carId}
                />
            )}
        </>
    );
}

export type UniversalProductListProps = {
    categoryId: string;
    carId?: string;
    title?: string;
};

export function UniversalProductList({ categoryId, carId, title }: UniversalProductListProps) {
    const viewMode = useProductListMode();

    const { data: filters, isLoading: isLoadingFilters } = useUniversalProductListFacets({
        type: viewMode,
        categoryId,
    });

    const { data: vehicle } = useCarInfo();

    const { query, getNextPage } = useUniversalProductList({
        type: viewMode,
        categoryId,
        vehicleClass: vehicle?.vehicleClass,
    });

    const { data: { pages: [page] = [] } = {} } = query;

    const sortedSisterCategories = useSortedCategories(page?.sisterCategories);

    return (
        <>
            <ProductListHead
                title={title}
                isLoadingFilters={isLoadingFilters}
                filters={filters}
                sisterCategories={sortedSisterCategories}
                categoryId={categoryId}
            />
            {viewMode === 'tile' ? (
                <ProductTileView
                    query={query as UseInfiniteQueryResult<ProductTypes.IPagedCategoryResultOfProductTile>}
                    onGetNextPage={getNextPage}
                />
            ) : (
                <ProductListView
                    carId={carId}
                    query={query as UseInfiniteQueryResult<ProductTypes.IPagedCategoryResultOfProduct>}
                    onGetNextPage={getNextPage}
                />
            )}
        </>
    );
}

export type ProductListViewProps = {
    query: UseInfiniteQueryResult<ProductTypes.IPagedCategoryResultOfProduct>;
    onGetNextPage: () => void;
    carId?: string;
};

export function ProductListView({ query, onGetNextPage, carId }: ProductListViewProps) {
    const [modalContent, setModalContent] = useState<JSX.Element | null>(null);

    if (query.isLoading) {
        return <Loader padding="100px 0" />;
    }

    return (
        <>
            {modalContent && (
                <LightBox isOpen={!!modalContent} onClose={() => setModalContent(null)}>
                    {modalContent}
                </LightBox>
            )}
            <div>
                <ProductListRowPages
                    pages={query?.data?.pages}
                    setModalContent={setModalContent}
                    isLoadingMore={query.isFetchingNextPage}
                    carId={carId}
                />
            </div>

            {query.isFetchingNextPage && <Loader padding="100px 0" />}

            <LoadMore
                onClick={() => onGetNextPage()}
                total={query?.data?.pages?.[0]?.itemCount}
                loaded={query?.data?.pages?.reduce((a, b) => {
                    return a + (b.items || []).length;
                }, 0)}
                fetching={query.isFetchingNextPage}
            />
        </>
    );
}

export type ProductTileViewProps = {
    query: UseInfiniteQueryResult<ProductTypes.IPagedCategoryResultOfProductTile>;
    onGetNextPage: () => void;
};

export function ProductTileView({ query, onGetNextPage }: ProductTileViewProps) {
    const [modalContent, setModalContent] = useState<JSX.Element | null>(null);

    if (query.isLoading) {
        return <Loader padding="100px 0" />;
    }

    return (
        <>
            {modalContent && (
                <LightBox isOpen={!!modalContent} onClose={() => setModalContent(null)}>
                    {modalContent}
                </LightBox>
            )}

            <div>
                <ProductListTilePages pages={query?.data?.pages} setModalContent={setModalContent} />
            </div>

            {query.isFetchingNextPage && <Loader padding="100px 0" />}

            <LoadMore
                onClick={() => onGetNextPage()}
                total={query?.data?.pages?.[0]?.itemCount}
                loaded={query?.data?.pages?.reduce((a, b) => {
                    return a + (b.items || []).length;
                }, 0)}
                fetching={query.isFetchingNextPage}
            />
        </>
    );
}

export type UseUniversalProductListProps = {
    type: ProductListType;
    categoryId: string;
    pageSize?: number;
    vehicleClass?: `${Vehicles.VehicleClass}` | Vehicles.VehicleClass;
};

const CATALOG_TYPE_MAP = {
    [Vehicles.VehicleClass.Unknown]: ProductTypes.CatalogType.SparePart,
    [Vehicles.VehicleClass.Standard]: ProductTypes.CatalogType.SparePart,
    [Vehicles.VehicleClass.Heavy]: ProductTypes.CatalogType.TruckAndTrailer,
};

export function useUniversalProductList({ type, categoryId, pageSize, vehicleClass }: UseUniversalProductListProps) {
    return useProductList<ProductTypes.IPagedCategoryResultOfProduct | ProductTypes.IPagedCategoryResultOfProductTile>({
        endpoint:
            type === 'list'
                ? `${endpoints.products.getProductList}/categories/${categoryId}`
                : `${endpoints.products.getProductList}/categories/${categoryId}/tiles`,
        catalogType: vehicleClass ? CATALOG_TYPE_MAP[vehicleClass] : ProductTypes.CatalogType.UniversalCatalog,
        getNextPageParam: (page) => Number(page.paging?.pageIndex) + 1,
        type,
        pageSize,
    });
}

export type UsePictogramProductListProps = {
    type: ProductListType;
    pictogramId?: string;
    pictogramGroupAlias?: string;
    pictogramGroupType?: string;
};

export function usePictogramProductList({ type, pictogramId, pictogramGroupAlias, pictogramGroupType }: UsePictogramProductListProps) {
    return useProductList<ProductTypes.IPagedCategoryResultOfProduct | ProductTypes.IPagedCategoryResultOfProductTile>({
        endpoint:
            type === 'list'
                ? `${endpoints.products.getProductList}/pictograms/${pictogramId}/groups/${pictogramGroupAlias}/${pictogramGroupType}`
                : `${endpoints.products.getProductList}/pictograms/${pictogramId}/groups/${pictogramGroupAlias}/${pictogramGroupType}/tiles`,
        getNextPageParam: (page) => Number(page.paging?.pageIndex) + 1,
        enabled: !!pictogramId && !!pictogramGroupAlias && !!pictogramGroupType,
        type,
    });
}

export type UseExhaustProductListProps = {
    exhaustSystemId?: string;
    type: ProductListType;
};

export function useExhaustProductList({ type, exhaustSystemId }: UseExhaustProductListProps) {
    return useProductList<ProductTypes.IPagedResultOfProduct>({
        endpoint:
            type === 'list'
                ? `${endpoints.products.getProductList}/exhausts/${exhaustSystemId}`
                : `${endpoints.products.getProductList}/exhausts/${exhaustSystemId}/tiles`,
        getNextPageParam: (page) => Number(page.paging?.pageIndex) + 1,
        enabled: !!exhaustSystemId,
        type,
    });
}

export type UseSearchProductListProps = {
    type: ProductListType;
};

export function useSearchProductList({ type }: UseSearchProductListProps) {
    return useProductList<ProductTypes.IPagedResultOfProduct>({
        endpoint: type === 'list' ? endpoints.products.getSearchResultsList : `${endpoints.products.getSearchResultsList}/tiles`,
        getNextPageParam: (page) => Number(page.paging?.pageIndex) + 1,
        type,
    });
}
