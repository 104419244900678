import { useCallback, useState } from 'react';
import { Bff } from '~/models/bff';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import CreateDepositCredit from '~/shared/create-credit/create-deposit-credit/create-deposit-credit.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import styles from './styled.module.scss';
import { Modal } from '~/features/primitives/modal';
import { useRouter } from 'next/router';
import { useStaticContent } from '~/libs/queries/bff';

// Old component: deposit-button.component.tsx
export default function W045InternalReturnDeposit(props: Bff.IStandardModuleViewModel) {
    const router = useRouter();
    const translate = useTranslations();
    const [isOpen, setIsOpen] = useState(false);
    const { data: staticContent } = useStaticContent();

    const handleOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setIsOpen(false);
    }, []);

    const handleClose = useCallback(() => {
        setIsOpen(false);

        if (!staticContent?.urlMappings?.invoices) {
            return;
        }

        router.push(staticContent.urlMappings.invoices);
    }, [router, staticContent?.urlMappings?.invoices]);

    return (
        <div className={styles.container}>
            <Modal
                position="right"
                open={isOpen}
                onOpenChange={handleOpenChange}
                title={translate('createCredit.returnDeposit', 'Returner depositum')}
            >
                <CreateDepositCredit onClose={handleClose} />
            </Modal>

            <ButtonCircle iconName="refresh" onClick={() => setIsOpen(true)} text={translate('createCredit.returnDeposit', 'Returner depositum')} />
        </div>
    );
}
